<div class="icon-nav">
  <ul>



    <li class="onhover-div mobile-search" id="search-widgets">

      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>

      <div id="search-overlay" class="search-overlay" [ngStyle]="{ display: searchOn ? 'block' : 'none' }">
        <div>
          <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
          <div class="overlay-content">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <form>
                    <div class="form-group">
                      <input type="text" class="form-control" id="exampleInputPassword1"
                        placeholder="Tìm kiếmm sản phẩm " [formControl]="searchControl" (keydown)="onKeydown($event)"
                        #barSearchInput />
                    </div>
                    <button type="submit" class="btn btn-primary">

                    </button>

                  </form>
                </div>
              </div>

              <div class="row result">
                <div class="col-xl-12">
                  <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                    <section class="wishlist-section section-b-space">
                      <div class="container">
                        <div class="col-md-12 empty-cart-cls text-center" *ngIf="!resultSet.results.length">
                          <img src="assets/images/empty-wishlist.png" alt="Empty cart" title="Emprt cart" />
                          <h3 class="mt-4">
                            <b>Không thấy sản phẩm !</b>
                          </h3>
                        </div>
                        <div class="row" *ngIf="resultSet.results.length">
                          <div class="col-sm-12">
                            <table class="table cart-table table-responsive-xs">
                              <thead>
                                <tr class="table-head">
                                  <th scope="col">Ảnh</th>
                                  <th scope="col">Sản phẩm</th>
                                  <th scope="col">Gía</th>
                                  <th scope="col">Tình trạng</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody *ngFor="let product of resultSet.results">
                                <tr>
                                  <td>
                                    <a (click)="openProduct(product)">
                                      <img [src]="product.images[0].src" [alt]="product.images[0].alt" />
                                    </a>
                                  </td>
                                  <td>
                                    <a (click)="openProduct(product)">{{ product.title | titlecase }}</a>
                                    <div class="mobile-cart-content row">
                                      <div class="col-xs-3">
                                        <p>
                                          {{
                                          product.stock > 0
                                          ? "in stock"
                                          : "out of stock"
                                          }}
                                        </p>
                                      </div>
                                      <div class="col-xs-3">
                                        <h2 class="td-color">
                                          {{
                                          (product.price
                                          | discount: product) *
                                          productService?.Currency.price
                                          | currency
                                          : productService?.Currency
                                          .currency
                                          : "symbol"
                                          }}
                                        </h2>
                                      </div>
                                      <div class="col-xs-3">
                                        <h2 class="td-color">
                                          <a [routerLink]="['/shop/cart']" (click)="addToCart(product)" class="cart">
                                            <i class="ti-shopping-cart"></i>
                                          </a>
                                        </h2>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <h2>
                                      {{
                                      (product.price | discount: product) *
                                      productService?.Currency.price
                                      | currency
                                      : productService?.Currency.currency
                                      : "symbol"
                                      }}
                                    </h2>
                                  </td>
                                  <td>
                                    <p>
                                      {{
                                      product.stock > 0
                                      ? "Còn hàng "
                                      : "Hết hàng "
                                      }}
                                    </p>
                                  </td>
                                  <td>

                                    <a href="javascript:void(0)" (click)="addToCart(product)" class="cart">
                                      <i class="ti-shopping-cart"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>

    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/shop/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="" />
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart" *ngIf="!products.length">
        <h5>Hiện tại giỏ hàng không có sản phẩm nào !.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf="products.length">
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/shop/product/', product.id, product.hadle]">
              <img class="mr-3" [src]="product?.images[0].src" [alt]="product?.images[0].alt" />
            </a>
            <div class="media-body">
              <a [routerLink]="['/shop/product/', product.id, product.handle]">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x
                  {{
                  product?.price * productService?.Currency.price
                  | discount: product
                  | currency: productService?.Currency.currency:"symbol"
                  }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>
              Tạm tính :
              <span>{{
                getTotal
                | async
                | currency: productService?.Currency.currency:"symbol"
                }}</span>
            </h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart">Xem giỏ hàng </a>
            <a [routerLink]="['/shop/checkout']" class="checkout">Thanh toán
            </a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>