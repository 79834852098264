import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject, Observable, take } from "rxjs";
import { BlogsService } from "./blogs.service";
import { CategoriesService } from "./categories.service";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  image?: string;
  active?: boolean;
  badge?: boolean;
  badgeText?: string;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor(
    private _blogsService: BlogsService,
    private _categoriesService: CategoriesService
  ) { }

  public screenWidth: any;
  public leftMenuToggle: boolean = false;
  public mainMenuToggle: boolean = false;

  private _items: BehaviorSubject<Menu[] | null> = new BehaviorSubject(null);
  private _leftMenuItems: BehaviorSubject<Menu[] | null> = new BehaviorSubject(
    null
  );

  // items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  // leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

  private childrenProductsMenu: Menu[] = [];
  private childrenProductsMenu1: Menu[] = [];
  private childrenProductsMenu2: Menu[] = [];
  private childrenProductsMenu3: Menu[] = [];
  private childrenProductsMenu4: Menu[] = [];
  private childrenProductsMenu5: Menu[] = [];
  private childrenProductsMenu6: Menu[] = [];


  private childrenProductsLeftMenu: Menu[] = [];
  private childrenProductsLeftMenu1: Menu[] = [];
  private childrenProductsLeftMenu2: Menu[] = [];
  private childrenProductsLeftMenu3: Menu[] = [];
  private childrenProductsLeftMenu4: Menu[] = [];
  private childrenProductsLeftMenu5: Menu[] = [];
  private childrenProductsLeftMenu6: Menu[] = [];

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      path: "/home",
      title: "Trang chủ",
      type: "link",
      active: false,
    },
    {
      path: "/pages/aboutus",
      title: "Giới thiệu",
      type: "link",
      active: false,
    },

    {
      title: "Sản phẩm",
      type: "sub",
      megaMenu: true,
      badge: true,
      badgeText: "mới",
      active: false,
      children: [],
    },

    {
      path: "/blog",
      title: "Tin tức",
      type: "link",
      active: false,
    },
    {
      path: "/pages/contact",
      title: "Liên hệ",
      type: "link",
      active: false,
    },
    {
      path: "http://tracuuhoadon.minvoice.com.vn/",
      title: "Hóa đơn",
      type: "extLink",
      active: false,
    },
  ];

  get items$(): Observable<Menu[]> {
    return this._items.asObservable();
  }

  get leftMenuItems$(): Observable<Menu[]> {
    return this._leftMenuItems.asObservable();
  }

  resetMenuItems(): void {
    this._items.next(null);

    this.childrenProductsMenu = [];
    this.childrenProductsMenu1 = [];
    this.childrenProductsMenu2 = [];
    this.childrenProductsMenu3 = [];
    this.childrenProductsMenu4 = [];
    this.childrenProductsMenu5 = [];

    this.childrenProductsMenu6 = [];
    // get products menu items
    this._categoriesService
      .getCategoriesByType("1")
      .pipe(take(1))
      .subscribe((categories) => {
        categories.forEach((category) => {

          const item = {
            path: "/shop/category/" + category.id + "/" + category.title + "/1",
            title: category.title,
            type: "link",
          };
          this.childrenProductsMenu1.push(item);

          const item1 = {
            path: "/shop/category/" + category.id + "/" + category.title + "/1",
            title: category.title,
            type: "sub",
          };
          this.childrenProductsLeftMenu1.push(item1);


        });
      });

    this._categoriesService
      .getCategoriesByType("2")
      .pipe(take(1))
      .subscribe((categories) => {
        categories.forEach((category) => {
          const item = {
            path: "/shop/category/" + category.id + "/" + category.title + "/2",
            title: category.title,
            type: "link",
          };
          this.childrenProductsMenu2.push(item);

          const leftitem = {
            path: "/shop/category/" + category.id + "/" + category.title + "/2",
            title: category.title,
            type: "sub",
          };
          this.childrenProductsLeftMenu2.push(leftitem);


        });
      });

    this._categoriesService
      .getCategoriesByType("3")
      .pipe(take(1))
      .subscribe((categories) => {
        categories.forEach((category) => {
          const item = {
            path: "/shop/category/" + category.id + "/" + category.title + "/3",
            title: category.title,
            type: "link",
          };
          this.childrenProductsMenu3.push(item);

          const leftItem = {
            path: "/shop/category/" + category.id + "/" + category.title + "/3",
            title: category.title,
            type: "sub",
          };
          this.childrenProductsLeftMenu3.push(leftItem);

        });
      });

    this._categoriesService
      .getCategoriesByType("4")
      .pipe(take(1))
      .subscribe((categories) => {
        categories.forEach((category) => {
          const item = {
            path: "/shop/category/" + category.id + "/" + category.title + "/4",
            title: category.title,
            type: "link",
          };
          this.childrenProductsMenu4.push(item);

          const leftItem = {
            path: "/shop/category/" + category.id + "/" + category.title + "/4",
            title: category.title,
            type: "sub",
          };
          this.childrenProductsLeftMenu4.push(leftItem);

        });
      });

    this._categoriesService
      .getCategoriesByType("5")
      .pipe(take(1))
      .subscribe((categories) => {
        categories.forEach((category) => {
          const item = {
            path: "/shop/category/" + category.id + "/" + category.title + "/5",
            title: category.title,
            type: "link",
          };
          this.childrenProductsMenu5.push(item);

          const leftItem = {
            path: "/shop/category/" + category.id + "/" + category.title + "/5",
            title: category.title,
            type: "sub",
          };
          this.childrenProductsLeftMenu5.push(leftItem);

        });
      });

    this._categoriesService
      .getCategoriesByType("6")
      .pipe(take(1))
      .subscribe((categories) => {
        categories.forEach((category) => {
          const item = {
            path: "/shop/category/" + category.id + "/" + category.title + "/6",
            title: category.title,
            type: "link",
          };
          this.childrenProductsMenu6.push(item);

          const leftItem = {
            path: "/shop/category/" + category.id + "/" + category.title + "/6",
            title: category.title,
            type: "sub",
          };
          this.childrenProductsLeftMenu6.push(leftItem);

        });
      });

    // product menu
    const productLeftMenu1 = {
      path: "/shop/collection/1",
      title: "Dược phẩm",
      type: "sub",
      active: false,
      children: this.childrenProductsMenu1,
    };
    const productLeftMenu2 = {
      path: "/shop/collection/2",
      title: "Thiết bị y tế",
      type: "sub",
      active: false,
      children: this.childrenProductsMenu2,
    };
    const productLeftMenu3 = {
      path: "/shop/collection/3",
      title: "Thực phẩm dinh dưỡng",
      type: "sub",
      active: false,
      children: this.childrenProductsMenu3,
    };
    const productLeftMenu4 = {
      path: "/shop/collection/4",
      title: "Nội thất ",
      type: "sub",
      active: false,
      children: this.childrenProductsMenu4,
    };

    const productLeftMenu5 = {
      path: "/shop/collection/5",
      title: "Mỹ phẩm ",
      type: "sub",
      active: false,
      children: this.childrenProductsMenu5,
    };

    const productLeftMenu6 = {
      path: "/shop/collection/6",
      title: "Dược liệu ",
      type: "sub",
      active: false,
      children: this.childrenProductsMenu6,
    };

    // menu
    // product menu
    const productMenu1 = {
      path: "/shop/collection/1",
      title: "Dược phẩm",
      type: "link",
      active: false,
      children: this.childrenProductsMenu1,
    };
    const productMenu2 = {
      path: "/shop/collection/2",
      title: "Thiết bị y tế",
      type: "link",
      active: false,
      children: this.childrenProductsMenu2,
    };
    const productMenu3 = {
      path: "/shop/collection/3",
      title: "Thực phẩm dinh dưỡng",
      type: "link",
      active: false,
      children: this.childrenProductsMenu3,
    };
    const productMenu4 = {
      path: "/shop/collection/4",
      title: "Nội thất ",
      type: "link",
      active: false,
      children: this.childrenProductsMenu4,
    };

    const productMenu5 = {
      path: "/shop/collection/5",
      title: "Mỹ phẩm ",
      type: "link",
      active: false,
      children: this.childrenProductsMenu5,
    };

    const productMenu6 = {
      path: "/shop/collection/6",
      title: "Dược liệu ",
      type: "link",
      active: false,
      children: this.childrenProductsMenu6,
    };

    if (this.childrenProductsMenu1.length > 0) {
      this.childrenProductsMenu.push(productMenu1);
    }

    const productsMenu = {
      path: "/shop/collection/1",
      title: "Sản phẩm",
      type: "link",
      active: false,
      children: [
        productMenu1,
        productMenu2,
        productMenu3,
        productMenu4,
        productMenu5,
        productMenu6,
      ],
    };


    if (this.MENUITEMS.length > 0) {
      this.MENUITEMS.splice(2, 1, productsMenu);
    }

    this._items.next(this.MENUITEMS);
    this._leftMenuItems.next([
      productLeftMenu1,
      productLeftMenu2,
      productLeftMenu3,
      productLeftMenu4,
      productLeftMenu5,
      productLeftMenu6,
    ]);
  }

  resetLeftMenuItems(): void {
    this._leftMenuItems.next(null);

    // this._leftMenuItems.next(this.LEFTMENUITEMS);
  }
}
