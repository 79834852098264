import { Component, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

import { Store } from '@ngxs/store';
import { AuthService } from "./shared/services/auth/auth.service";
import { UpdateCurrentUserSignIned } from "./shared/services/auth/auth.actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    translate: TranslateService,
    private _store: Store, private _authService: AuthService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang("en");
      translate.addLangs(["en", "fr"]);
    }


    // check signined and not update state call action update sate auth
    this._store
      .select((state) => state.auth.user)

      .subscribe((user: any) => {

        if (!user) {

          const currentUser = this._authService.getCurrentUserSignIned();
          currentUser.subscribe((_user: any) => {
            if (_user == null) {
              return;
            }
          
            this._store.dispatch(new UpdateCurrentUserSignIned(_user));
          });
        }
      });
  }
}
