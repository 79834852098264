<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-5">
          <div class="header-contact font-size-20 font-weight-bold">
            <ul>
              <li>CÔNG TY TNHH DƯỢC PHẨM PHAN RANG</li>
              <li>
                <i class="fa fa-phone" aria-hidden="true"></i>0259 3834059
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-7 text-right">
          <ul class="header-dropdown">
            <li class="compare">
              <app-settings-search> </app-settings-search>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Thích</a>
            </li>

            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i>
              {{ userLogined ? userLogined.displayName : "Tài khoản " }}
              <ul class="onhover-show-div">
                <li>
                  <a data-lng="en" [routerLink]="['../account/dashboard']" *ngIf="userLogined">
                    Tài khoản của tôi
                  </a>
                </li>
                <li>
                  <a data-lng="en" [routerLink]="['../account/login']" *ngIf="!userLogined">
                    Đăng nhập
                  </a>
                </li>
                <li>
                  <a data-lng="en" [routerLink]="['../account/register']" *ngIf="!userLogined">
                    Đăng ký
                  </a>
                </li>
                <li>
                  <a data-lng="es" *ngIf="userLogined" (click)="logout()">
                    Thoát
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo" />
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>