import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../../models/category.model';
import { CategoriesService } from '../../services/categories.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnChanges {

  @Input() typeId: string = '';


  public collapse: boolean = true;

  categories$: Observable<Category[]>;

  constructor(private _categoriesService: CategoriesService,
    private _changeDetectorRef: ChangeDetectorRef) {


  }
  ngOnChanges(changes: SimpleChanges): void {
    this.categories$ = this._categoriesService.getCategoriesByType(this.typeId);
    this._changeDetectorRef.markForCheck();
  }

  ngOnInit(): void {
    this.categories$ = this._categoriesService.getCategoriesByType(this.typeId);
    this._changeDetectorRef.markForCheck();

  }


  // get filterbyCategory() {
  //   const category = [...new Set(this.products.map(product => product.type))]
  //   return category
  // }

}
