<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
  <h3 class="collapse-block-title" (click)="collapse = !collapse">
    Danh mục sản phẩm
  </h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ul class="category-list" *ngIf="categories$ | async as categories">
        <li *ngFor="let category of categories">
          <a [routerLink]="['/shop/category/'+category.id+'/'+category.title+'/'+typeId]">
            {{ category.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- End Categories List -->