<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>CẦN BIẾT THÔNG TIN SẢN PHẨM!</h4>
								<p>Hãy đăng ký email để biết thông tin sản phẩm mới nhất!. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
									placeholder="Nhập email ">
							</div>
							<button type="submit" class="btn btn-solid">Đăng ký</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space skyblue">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4></h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>Công ty TNHH Dược Phẩm Phan Rang
							<br>
							Địa chỉ: Lô 72 Khu QH, Nguyễn Văn Cừ, Văn Hải,
							<br> Phan Rang - Tháp Chàm, Ninh Thuận
						</p>
						<div class="footer-social">
							<ul>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-google-plus"
											aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Sản phẩm - Dịch vụ</h4>
						</div>
						<div class="footer-contant" *ngIf="types$ | async as types">
							<ul *ngFor="let type of types">
								<li><a href="javascript:void(0)" [routerLink]="['/shop/collection/'+type.id]">
										{{type.title}} </a></li>

							</ul>
						</div>
					</div>
				</div>
				<!-- <div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>why we choose</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li><a href="javascript:void(0)">shipping & return</a></li>
								<li><a href="javascript:void(0)">secure shopping</a></li>
								<li><a href="javascript:void(0)">gallary</a></li>
								<li><a href="javascript:void(0)">affiliates</a></li>
								<li><a href="javascript:void(0)">contacts</a></li>
							</ul>
						</div>
					</div>
				</div> -->
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Thông tin liên hệ</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-list">
								<li><i class="fa fa-map-marker"></i>Địa chỉ: Lô 72 Khu QH, Nguyễn Văn Cừ, Văn Hải, Phan
									Rang - Tháp Chàm, Ninh Thuận
								</li>
								<li><i class="fa fa-phone"></i>Điện thoại: 0259 3834059</li>
								<li><i class="fa fa-envelope-o"></i>phanrangpharma@gmail.com</li>
								<li><i class="fa fa-fax"></i>Fax: 0259 3834609</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Phan Rang Pharma Co,
							Ltd</p>
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->