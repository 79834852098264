import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { User } from "../../models/user.model";
import { AuthService } from "../../services/auth/auth.service";
import { NavService } from "../../services/nav.service";

@Component({
  selector: "app-header-one",
  templateUrl: "./header-one.component.html",
  styleUrls: ["./header-one.component.scss"],
})
export class HeaderOneComponent implements OnInit {
  @Input() class: string;
  @Input() themeLogo: string = "assets/images/logos/logo.png"; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  public stick: boolean = false;

  userLogined: any;

  constructor(
    private _store: Store,
    private _authService: AuthService,
    private _router: Router,
    private navServices: NavService
  ) {}

  ngOnInit(): void {
    this._store
      .select((state) => state.auth.user)
      .subscribe((user) => {
        this.userLogined = user;
      });
  }

  logout(): void {
    this.navServices.resetMenuItems();
    this._authService.logout();
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }
}
