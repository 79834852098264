import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Action, State, StateContext } from '@ngxs/store';

// update currentRouter before navigate
export class Navigate {
    static readonly type = '[router] navigate';
    constructor(public payload: string) { }
}

// update currentRouter and not navigate
export class UpdateCurrentRouter {
    static readonly type = '[Router] update current router';
    constructor(public payload: string) { }
}

@State<string>({
    name: 'router',
    defaults: ''
})


@Injectable()
export class RouterState {
    constructor(private router: Router) { }

    @Action(Navigate)
    async changeRoute(context: StateContext<string>, action: Navigate): Promise<void> {
        const path = action.payload;
        // context.setState(path);
        await this.router.navigate([path]);
        context.setState(path);
    }

    @Action(UpdateCurrentRouter)
    async updateCurrentRouter(context: StateContext<string>, action: UpdateCurrentRouter): Promise<void> {
        const path = action.payload;
        await context.setState(path);
    }
}
