<!-- tap to top -->
<div
  class="tap-top top-cls"
  (click)="tapToTop()"
  [ngStyle]="{ display: show ? 'block' : 'none' }"
>
  <div>
    <i class="fa fa-angle-double-up"></i>
  </div>
</div>

<!-- tap to top -->

<!-- tap to top -->
<!-- <div class="phonumber-top top-cls" [ngStyle]="{ 'display':  'block'  }">
  <div>
    <i class="fa fa-phone" aria-hidden="true"></i> 0259 3834059
  </div>

</div> -->

<!-- tap to top -->
