import { ProvinceService } from "./../../../shared/services/province.service";
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, take } from "rxjs";
import { Order } from "src/app/shared/models/order.model";

import { environment } from "src/environments/environment";

import * as CryptoJS from "crypto-js";
import { ProductService } from "src/app//shared/services/product.service";
import { OrderService } from "src/app/shared/services/order.service";
import { Utils } from "../../utils";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],
})
export class OrderDetailComponent implements OnInit {
  @Input() orderId: string = "";

  orderDetails$: Observable<Order>;

  provinces: any;
  districts: any;
  communes: any;
  loading = true;
  shipDate;

  constructor(
    public productService: ProductService,
    private orderService: OrderService,
    private _activatedRoute: ActivatedRoute,
    private _provinceService: ProvinceService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    // delete cartItem
    localStorage.removeItem("cartItems");

    if (this.orderId) {
      this.orderDetails$ = this.orderService.getOrder(this.orderId);
      this.orderDetails$.pipe(take(2)).subscribe((orderDetails: any) => {
        if (orderDetails) {
          this._provinceService
            .getProvinceNameByCode(orderDetails.shippingDetails.province)
            .subscribe((data) => {
              console.log(data);
              this.provinces = data[0].name;
            });

          this._provinceService
            .getDistrictNameByCode(orderDetails.shippingDetails.district)
            .pipe(take(1))
            .subscribe((data) => {
              this.districts = data[0].name;
            });
          this._provinceService
            .getCommuneNameByCode(orderDetails.shippingDetails.commune)
            .pipe(take(1))
            .subscribe((data) => {
              this.communes = data[0].name;
              this.loading = false;
            });

          // ship date
          this.shipDate = Utils.addDaysAndConvertDateString(
            orderDetails.timestamp.toDate(),
            5
          );
        }
      });
    } else {
      this._activatedRoute.queryParams.subscribe((params) => {
        let data = params;
        let checksumData =
          data.appid +
          "|" +
          data.apptransid +
          "|" +
          data.pmcid +
          "|" +
          data.bankcode +
          "|" +
          data.amount +
          "|" +
          data.discountamount +
          "|" +
          data.status;
        let checksum = CryptoJS.HmacSHA256(
          checksumData,
          environment.zalopay_callback_key
        ).toString();

        if (checksum != data.checksum) {
          // Invalid
        } else {
          // kiểm tra xem đã nhận được callback hay chưa, nếu chưa thì tiến hành gọi API truy vấn trạng thái thanh toán của đơn hàng để lấy kết quả cuối cùng

          this.orderDetails$ = this.orderService.getOrder(data.apptransid);
        }
      });
    }
  }
}
