import {
  Component,
  OnInit,
  Injectable,
  PLATFORM_ID,
  Inject,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import {
  debounceTime,
  filter,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { FormControl } from "@angular/forms";
import { SearchService } from "../../services/searchService";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { UpdateCurrentRouter } from "../../router.state";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {



  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @Input() searchType: "all" | "books" | "reviews" | "videos" | "podcasts" =
    "all";

  resultSets: any[];
  searchControl: FormControl = new FormControl();

  debounce: number = 300;
  minLength: number = 2;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public products: Product[] = [];

  public searchOn: boolean = false;

  public languages = [
    {
      name: "English",
      code: "en",
    },
  ];

  public currencies = [

    {
      name: "Dollar",
      currency: "USD",
      price: 1, // price of usd
    },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    private _router: Router,
    private _searchService: SearchService,
    private _store: Store
  ) {
    this.productService.cartItems.subscribe(
      (response) => (this.products = response)
    );
  }

  @ViewChild("barSearchInput")
  set barSearchInput(value: ElementRef) {
    // If the value exists, it means that the search input
    // is now in the DOM and we can focus on the input..
    if (value) {
      // Give Angular time to complete the change detection cycle
      setTimeout(() => {
        // Focus to the input element
        value.nativeElement.focus();
      });
    }
  }

  ngOnInit(): void {
    // Subscribe to the search field value changes
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          // Set the resultSets to null if there is no value or
          // the length of the value is smaller than the minLength
          // so the autocomplete panel can be closed
          if (!value || value.length < this.minLength) {
            this.resultSets = null;
          }

          // Continue
          return value;
        }),
        // Filter out undefined/null/false statements and also
        // filter out the values that are smaller than minLength
        filter((value) => value && value.length >= this.minLength)
      )
      .subscribe((value) => {

        this._searchService.initAlgoliaSearch();

        this._searchService
          .myAlgoliaSearch(value, 10, this.searchType)
          .then((resultSets) => {
            this._store.dispatch(new UpdateCurrentRouter(this._router.url));

            this.resultSets = resultSets;

            // Execute the event
            this.search.next(resultSets);
          });
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    // this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  onKeydown(event: KeyboardEvent): void { }

  searchToggle() {
    this.resultSets = [];
    this.search.next([]);
    this.searchOn = !this.searchOn;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.objectID || index;
  }
  async addToCart(product: any) {
    const status = await this.productService.addToCart(product);
    if (status) {
      this._router.navigate(["/shop/cart"]);
      this.removeItem(product);
      this.searchToggle();
    }
  }

  openProduct(product: any) {
    this._router.navigate(['/shop/product/',
      product.objectID,
      product.handle]);
    this.searchToggle();

  }

}
