import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category, Type } from '../models/category.model';
import { Utils } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private _afs: AngularFirestore) { }

  addCategory(data: any): string {
    data.handle = Utils.handleize(data.title);
    const id = this._afs.createId();
    this._afs
      .collection('categories')
      .doc(id)
      .set({ ...data })
      .then()
      .catch(() => null);
    return id;
  }

  deleteCategory(categoryId: string): any {
    return this._afs.collection('categories').doc(categoryId).delete();
  }

  getCategory(categoryId: string): Observable<any> {
    return this._afs.collection('categories').doc(categoryId).valueChanges();
  }

  updateCategory(categoryId: string, cate: any): any {
    cate.handle = Utils.handleize(cate.title);
    return this._afs.collection('categories').doc(categoryId).update(cate);
  }

  getCategories(): Observable<any> {
    return this._afs
      .collection<Category>('categories')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Category;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getCategoriesByType(typeId: string): Observable<any> {
    return this._afs
      .collection<Category>('categories', (ref) =>
        ref.where('typeId', '==', typeId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Category;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getTypes(): Observable<any> {
    return this._afs
      .collection<Type>('types')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Type;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }
  getType(typeId: string): Observable<any> {
    return this._afs.collection('types').doc(typeId).valueChanges();
  }

}
