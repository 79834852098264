import { Injectable } from "@angular/core";

// import * as algoliasearch from 'algoliasearch';
import algoliasearch from "algoliasearch/lite";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  searchClient: any;

  constructor() {
    // const index = this.searchClient.initIndex('prod_books');
    // // only query string
    // index.search('muon kiep nhan sinh').then(({ hits }) => {
    //     console.log(hits);
    // });
    // // with params
    // index.search('muon kiep nhan sinh', {
    //     attributesToRetrieve: ['firstname', 'lastname'],
    //     hitsPerPage: 50,
    // }).then(({ hits }) => {
    //     console.log(hits);
    // });
  }

  initAlgoliaSearch(): void {
    this.searchClient = algoliasearch(
      "3WP505SCRF",
      "62e9a986b89005023aff50021a80b7c4"
    );
  }

  async myAlgoliaSearch(
    searchString: string,
    hitsPerPage: number,
    searchType: string
  ): Promise<any> {
    // const index = this.searchClient.initIndex('prod_books');
    // // only query string
    // index.search('muon kiep nhan sinh').then(({ hits }) => {
    //     console.log(hits);
    // });
    // // with params
    // index.search('muon kiep nhan sinh', {
    //     hitsPerPage: 50,
    // }).then(({ hits }) => {
    //     console.log(hits);
    // });

    let _productsResults: any;
    let _reviewsResults: any;
    let _podcastsResults: any;
    let _videosResults: any;

    switch (searchType) {
      case "all": {
        const productSearch = this.searchClient.initIndex("prod_products");

        _productsResults = await productSearch.search(searchString, {
          hitsPerPage: hitsPerPage,
        });

        break;
      }
      // case "books": {
      //   const booksSearch = this.searchClient.initIndex("prod_books");

      //   _booksResults = await booksSearch.search(searchString, {
      //     hitsPerPage: hitsPerPage,
      //   });

      //   break;
      // }
      // case "reviews": {
      //   const reviewsSearch = this.searchClient.initIndex("prod_reviews");

      //   _reviewsResults = await reviewsSearch.search(searchString, {
      //     hitsPerPage: hitsPerPage,
      //   });

      //   break;
      // }
      // case "podcasts": {
      //   const podcastsSearch = this.searchClient.initIndex("prod_podcasts");

      //   _podcastsResults = await podcastsSearch.search(searchString, {
      //     hitsPerPage: hitsPerPage,
      //   });

      //   break;
      // }
      // case "videos": {
      //   const videosSearch = this.searchClient.initIndex("prod_videos");

      //   _videosResults = await videosSearch.search(searchString, {
      //     hitsPerPage: hitsPerPage,
      //   });

      //   break;
      // }
      default: {
        //statements;
        break;
      }
    }

    // Prepare the results array
    const results = [];

    // If there are contacts results...
    if (_productsResults?.nbHits > 0) {
      const booksResults = _productsResults.hits;

      // Normalize the results
      booksResults.forEach((result) => {
        // Add a link
        result.link = "/shop/product/" + result.objectID + "/" + result.title;
      });

      // Add to the results
      results.push({
        id: "products",
        label: "Sản phẩm ",
        results: booksResults,
      });
    }

    // // If there are page results...
    // if (_reviewsResults?.nbHits > 0) {
    //   // Normalize the results
    //   const reviewsResults = _reviewsResults.hits;
    //   reviewsResults.forEach((result: any) => {
    //     // Add a link
    //     result.link = "/reviews/" + result.objectID;
    //   });

    //   // Add to the results
    //   results.push({
    //     id: "reviews",
    //     label: "Reviews Sách",
    //     results: reviewsResults,
    //   });
    // }

    // //Podcasts
    // if (_podcastsResults?.nbHits > 0) {
    //   const podcastsResults = _podcastsResults.hits;

    //   // Normalize the results
    //   podcastsResults.forEach((result) => {
    //     // Add a link
    //     result.link = "/podcasts/" + result.objectID;
    //   });

    //   // Add to the results
    //   results.push({
    //     id: "podcasts",
    //     label: "Podcasts Sách",
    //     results: podcastsResults,
    //   });
    // }

    // //Videos
    // if (_videosResults?.nbHits > 0) {
    //   const videosResults = _videosResults.hits;
    //   const newVideosResults = [];

    //   // Normalize the results
    //   videosResults.forEach((result) => {
    //     // Add a link
    //     result.link = "/books/" + result.bookId + "/" + result.title + "/3";
    //     if (result.bookId) {
    //       newVideosResults.push(result);
    //     }
    //   });

    //   results.push({
    //     id: "videos",
    //     label: "Videos Sách",
    //     results: newVideosResults,
    //   });
    // }

    return new Promise((resolve, reject) => {
      resolve(results);
    });
  }
}
