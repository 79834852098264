import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";
import { BlogsService } from "../../services/blogs.service";
import { CategoriesService } from "../../services/categories.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, OnDestroy {
  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.navServices.resetMenuItems();
    this.navServices.items$.subscribe(
      (menuItems) => (this.menuItems = menuItems)
    );
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.complete();
  }
}
