// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  instagram_token: "INSTA_TOKEN",
  stripe_token: "STRIPE_TOKEN",
  paypal_token: "PAYPAL_TOKEN",

  zalopay_app_id: "2554",
  zalopay_mac_key: "sdngKKJmqEMzvh5QQcdD2A9XBSKUNaYn",
  zalopay_callback_key: "trMrHtvjo6myautxDUiAcYsVtaeQ8nhf",
  zalopay_app_user: "demo",
  returnUrl: "https://pharnrangpharma.com/shop/checkout/success",
  callback_url:
    "https://us-central1-ctyduocphanrang.cloudfunctions.net/zaloPayCallback",
  zalopay_endpoint: "https://sb-openapi.zalopay.vn/v2/create",

  firebase: {
    apiKey: "AIzaSyDeHnRKJ0L_LeUw-qFiDhrHIr5E4TrIxtM",
    authDomain: "ctyduocphanrang.firebaseapp.com",
    projectId: "ctyduocphanrang",
    storageBucket: "ctyduocphanrang.appspot.com",
    messagingSenderId: "1054099638850",
    appId: "1:1054099638850:web:4ce65731580466d9617bdb",
    measurementId: "G-PN6WR8V9X8",
    // apiKey: "AIzaSyBgHqtdGQTA1pJY8M7Ts8Bgpccqw6opIrs",
    // authDomain: "phanrangpharmacom.firebaseapp.com",
    // projectId: "phanrangpharmacom",
    // storageBucket: "phanrangpharmacom.appspot.com",
    // messagingSenderId: "780316565424",
    // appId: "1:780316565424:web:934b062b2516026615c075",
    // measurementId: "G-LBL3EL5TMR",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
