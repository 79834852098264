import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ShopComponent } from "./shop/shop.component";
import { PagesComponent } from "./pages/pages.component";
import { ElementsComponent } from "./elements/elements.component";
import { AccountComponent } from "./account/account.component";
import { BlogComponent } from "./blog/blog.component";

import { AngularFireAuthGuard, canActivate, isNotAnonymous } from '@angular/fire/compat/auth-guard';


const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "shop",
    component: ShopComponent,
    loadChildren: () => import("./shop/shop.module").then((m) => m.ShopModule),
  },
  {
    path: "account",
    component: AccountComponent,
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
    canActivate: [AngularFireAuthGuard]
  },
  {
    path: "blog",
    component: BlogComponent,
    loadChildren: () => import("./blog/blog.module").then((m) => m.BlogModule),
  },
  {
    path: "pages",
    component: PagesComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "elements",
    component: ElementsComponent,
    loadChildren: () =>
      import("./elements/elements.module").then((m) => m.ElementsModule),
  },
  {
    path: "**", // Navigate to Home Page if not found any page
    redirectTo: "home",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
      // useHash: false,
      // anchorScrolling: "enabled",
      // scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
