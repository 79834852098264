export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: any) { }
}

export class SignInWithEmail {
    static readonly type = '[Auth] SignInWithEmail';
    constructor(public payload: any) { }
}


export class SignInWithProvider {
    static readonly type = '[Auth] SignInWithProvider';
    constructor(public payload: any) { }
}

export class UpdateCurrentUserSignIned {
    static readonly type = '[Auth] UpdateCurrentUserSignIned';
    constructor(public payload: any) { }
}

export class Logout {
    static readonly type = '[Auth] Logout';
}
