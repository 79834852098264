import { Component, OnInit, Input } from '@angular/core';
import { NewProductSlider } from '../../../data/slider';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../services/product.service';
import { type } from 'os';

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'Sản phẩm mới'; // Default
  @Input() type: string = null; // Default 

  public products: Product[] = [];

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService) {

    if (this.type) {
      this.productService.getNewProductsByType(this.type).subscribe(response =>
        // this.products = response.filter(item => item.type == this.type)
        this.products = response
      );
    } else {
      this.productService.getNewProducts().subscribe(response =>
        // this.products = response.filter(item => item.type == this.type)
        this.products = response
      );
    }


  }

  ngOnInit(): void {
  }

}
