import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, startWith, delay } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { Product } from "../models/product.model";

@Injectable()
export class ProvinceService {
  public Provinces;

  constructor(private http: HttpClient, private toastrService: ToastrService) {}

  // Provinces
  private get provinces(): Observable<any[]> {
    this.Provinces = this.http
      .get<any[]>("assets/data/provinces.json")
      .pipe(map((data) => data));
    return this.Provinces;
  }

  // Get provinces
  public get getProinces(): Observable<any[]> {
    return this.provinces;
  }

  public getProvinceNameByCode(code: string): Observable<any> {
    // return this.districts.pipe(
    //   map((txs) => txs.find((txn) => txn.province_code === code))
    // );

    const districts = this.http
      .get<any[]>("assets/data/provinces.json")
      .pipe(map((data) => data));

    return districts.pipe(
      map((items) => {
        return items.filter((item: any) => {
          return item.code === parseInt(code);
        });
      })
    );
  }

  public getDistrictNameByCode(code: string): Observable<any> {
    // return this.districts.pipe(
    //   map((txs) => txs.find((txn) => txn.province_code === code))
    // );

    const districts = this.http
      .get<any[]>("assets/data/districts.json")
      .pipe(map((data) => data));

    //  return districts.filter((item) => item.status == code)[0]
    //     .name;

    return districts.pipe(
      map((items) => {
        return items.filter((item: any) => {
          return item.code === parseInt(code);
        });
      })
    );
  }

  public getCommuneNameByCode(code: string): Observable<any> {
    // return this.districts.pipe(
    //   map((txs) => txs.find((txn) => txn.province_code === code))
    // );

    const districts = this.http
      .get<any[]>("assets/data/communes.json")
      .pipe(map((data) => data));

    return districts.pipe(
      map((items) => {
        return items.filter((item: any) => {
          return item.code === parseInt(code);
        });
      })
    );
  }

  // Get Products By Slug
  public getDistrictsByCode(code: string): Observable<any[]> {
    // return this.districts.pipe(
    //   map((txs) => txs.find((txn) => txn.province_code === code))
    // );

    const districts = this.http
      .get<any[]>("assets/data/districts.json")
      .pipe(map((data) => data));

    return districts.pipe(
      map((items) => {
        return items.filter((item: any) => {
          return item.province_code === parseInt(code);
        });
      })
    );
  }

  // Get Products By Slug
  public getDistricts(): Observable<any[]> {
    return this.http
      .get<any[]>("assets/data/districts.json")
      .pipe(map((data) => data));
  }

  // Get Products By Slug
  public getCommuneByCode(code: string): Observable<any[]> {
    const commnues = this.http
      .get<any[]>("assets/data/communes.json")
      .pipe(map((data) => data));
    return commnues.pipe(
      map((items) => {
        return items.filter((item: any) => {
          return item.district_code === parseInt(code);
        });
      })
    );
  }

  public getCommunes(): Observable<any[]> {
    return this.http
      .get<any[]>("assets/data/communes.json")
      .pipe(map((data) => data));
  }
}
