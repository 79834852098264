import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Type } from '../../models/category.model';
import { CategoriesService } from '../../services/categories.service';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/logos/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();

  types$: Observable<Type>;

  constructor(private _categoriesService: CategoriesService) { }

  ngOnInit(): void {
    this.types$ = this._categoriesService.getTypes();
  }

}
