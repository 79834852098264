<ng-template #videoModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <iframe
        *ngIf="video"
        [src]="
          blogsService.trustUrl(
            'https://www.youtube.com/embed/' + video.videoId
          )
        "
        allowfullscreen
      ></iframe>
    </div>
  </div>
</ng-template>
