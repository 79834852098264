import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Injectable,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { BlogsService } from "src/app/shared/services/blogs.service";

@Component({
  selector: "app-video-modal",
  templateUrl: "./video-modal.component.html",
  styleUrls: ["./video-modal.component.scss"],
})
export class VideoModalComponent implements OnInit, OnDestroy {
  @ViewChild("videoModal", { static: false }) VideoModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  video;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public blogsService: BlogsService
  ) {}

  ngOnInit(): void {
    this.blogsService.getVideo().subscribe((video) => {
      this.video = video;
    });
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.VideoModal, {
          size: "lg",
          ariaLabelledBy: "Video-Modal",
          centered: true,
          windowClass: "modal fade video-modal",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
