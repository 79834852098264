

import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { User } from '../../models/user.model';
import { Logout, UpdateCurrentUserSignIned } from './auth.actions';


export interface AuthStateModel {
  status: string;
  user: User;
}
const defaultUser = {
  status: null,
  user: null
};
@State<AuthStateModel>({
  name: 'auth',
  defaults: defaultUser
})
@Injectable()
export class AuthState {

  constructor(
  ) {

  }
  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.user;
  }


  @Action(UpdateCurrentUserSignIned)
  updateCurrentUserSignIned(context: StateContext<AuthStateModel>, action: UpdateCurrentUserSignIned): void {

    const _user = action.payload;
    context.patchState(
      {
        status: _user ? 'success' : null,
        user: _user
      }
    );
  }

  @Action(Logout)
  logout(context: StateContext<AuthStateModel>): void {

    context.patchState(
      {
        status: null,
        user: null
      }
    );
  }


}
