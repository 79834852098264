
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { User } from './models/user.model';


// declare Action update current user profile
export class UpdateCurrentUserProfile {
  static readonly type = '[UserProfile] update current user';
  constructor(public payload: any) { }
}

@State<User>({
  name: 'userProfile',
  defaults: null
})


// Declare State
@Injectable()
export class UserProfileState {
  constructor() { }


  @Action(UpdateCurrentUserProfile)
  async updateCurrentRouter(context: StateContext<string>, action: UpdateCurrentUserProfile): Promise<void> {
    const path = action.payload;
    await context.setState(path);
  }
}
